import {NavigationService} from '../services/NavigationService';
import {CheckoutService} from '../services/CheckoutService';

export const isPickupFlow = ({
  navigationService,
  checkoutService,
}: {
  navigationService: NavigationService;
  checkoutService: CheckoutService;
}) => {
  if (checkoutService.checkout) {
    return navigationService.isPickupFlow && !!checkoutService.checkout.selectedShippingOption?.pickupInfo;
  }
  return navigationService.isPickupFlow;
};
