import {FullName} from '@wix/ambassador-addresses-api-v1-address/types';

export class FullNameModel {
  public firstName: string;
  public lastName: string;

  constructor({firstName, lastName}: FullName) {
    this.firstName = firstName!;
    this.lastName = lastName!;
  }
}
