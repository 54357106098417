import {PaymentStoreProps} from '../../types/app.types';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {CheckoutService} from '../services/CheckoutService';
import {PaymentsWidgetConfiguration} from '@wix/cashier-payments-widget/src/types/PaymentsWidgetConfiguration';
import {NavigationService} from '../services/NavigationService';

export type PaymentStoreConfig = {
  flowAPI: ControllerFlowAPI;
  checkoutService: CheckoutService;
  navigationService: NavigationService;
};

export class PaymentStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly checkoutService: CheckoutService;
  private readonly navigationService: NavigationService;

  constructor({flowAPI, checkoutService, navigationService}: PaymentStoreConfig) {
    this.flowAPI = flowAPI;
    this.checkoutService = checkoutService;
    this.navigationService = navigationService;
  }

  public toProps(): PaymentStoreProps {
    const configuration: PaymentsWidgetConfiguration = {
      locale: this.flowAPI.translations.config.language,
      appId: this.flowAPI.controllerConfig.appParams.appDefinitionId,
      appInstanceId: this.flowAPI.controllerConfig.appParams.instanceId,
      amount: `${this.checkoutService.checkout.payNowTotalAfterGiftCard.amount}`,
      currency: this.checkoutService.checkout.currency!,
      msid: this.flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId as string,
      appInstance: this.flowAPI.controllerConfig.appParams.instance,
      visitorId: this.flowAPI.controllerConfig.platformAPIs.bi?.visitorId,
      siteOwnerId: this.flowAPI.controllerConfig.platformAPIs.bi?.ownerId,
      lang: this.flowAPI.translations.config.language,
      contactId: this.checkoutService.checkout.buyerInfo.contactId,
      viewMode: 'Site',
      country: 'US', // TODO
      isPrimaryLanguage: true, // TODO!
      isSignedInUser: false,
    };

    return {
      cashierWidgetProps: {
        configuration,
        locale: this.flowAPI.translations.config.language,
        theme: 'default',
        shouldApplySiteStyles: false,
        externalSubmitButton: true,
        walletPaymentInNewTab: false,
        allowManualPayment: true,
        isSaveCCEnabled: this.navigationService.isMyWalletAppInstalled,
        allowRecurringPaymentOnly: this.checkoutService.checkout.hasSubscriptionItems,
      },
      cashierConfiguration: configuration,
    };
  }
}
