import {ActiveStep, StepId} from '../../components/Checkout/CheckoutSteps/types';

export class StepsManagerService {
  private readonly stepsQueue: StepId[] = [];
  private readonly activeStep: ActiveStep = {
    stepId: null,
    stepIndex: 0,
  };

  public readonly goToNextStep = (): void => {
    this.activeStep.stepIndex++;
  };

  public readonly updateStepOnStage = (stepIndex: number, stepId: StepId | null): void => {
    this.activeStep.stepId = stepId;
    this.activeStep.stepIndex = stepIndex;
  };

  public readonly updateActiveStepId = (stepId: StepId): void => {
    this.activeStep.stepId = stepId;
    !this.stepsQueue.includes(stepId) && this.stepsQueue.push(stepId);
  };

  public readonly isStepInQueue = (stepId: StepId): boolean => {
    return this.stepsQueue.includes(stepId);
  };

  public readonly getLastStepInQueue = (): StepId | undefined => {
    return this.stepsQueue.length > 0
      ? this.stepsQueue[this.stepsQueue.length - 1]
      : /* istanbul ignore next */ undefined;
  };

  public readonly getActiveStep = (): ActiveStep => {
    return this.activeStep;
  };
}
