import {Street} from '@wix/ambassador-addresses-api-v1-address/types';

export class MemberStreetAddressModel {
  public number?: string;
  public name?: string;
  public apt?: string;

  constructor({number, name, apt}: Street) {
    this.number = number ?? '';
    this.name = name ?? '';
    this.apt = apt ?? '';
  }
}
