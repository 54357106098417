import {Address, TaxInfo, Location} from '@wix/ambassador-addresses-api-v1-address/types';
import {FullNameModel} from './FullName.model';
import {MemberStreetAddressModel} from './MemberStreetAddress.model';

export class MemberAddressModel {
  public id: string;
  public fullName: FullNameModel;
  public country?: string;
  public subdivision?: string;
  public city?: string;
  public zipCode?: string;
  public phoneNumber?: string;
  public company?: string;
  public email?: string;
  public addressLine1?: string;
  public addressLine2?: string;
  public street?: MemberStreetAddressModel;
  public type?: string;
  public taxInfo?: TaxInfo;
  public location?: Location;
  public hint?: string;

  constructor({
    id,
    fullName,
    country,
    subdivision,
    city,
    zipCode,
    phoneNumber,
    company,
    email,
    addressLine1,
    addressLine2,
    street,
    type,
    taxInfo,
    location,
    hint,
  }: Address) {
    this.id = id!;
    this.fullName = new FullNameModel(fullName!);
    this.country = country ?? '';
    this.subdivision = subdivision ?? '';
    this.city = city ?? '';
    this.zipCode = zipCode ?? '';
    this.phoneNumber = phoneNumber ?? '';
    this.company = company ?? '';
    this.email = email ?? '';
    this.addressLine1 = addressLine1 ?? '';
    this.addressLine2 = addressLine2 ?? '';
    this.street = street ? new MemberStreetAddressModel(street) : undefined;
    this.type = type ?? '';
    this.taxInfo = taxInfo ?? undefined;
    this.location = location ?? undefined;
    this.hint = hint ?? '';
  }
}
