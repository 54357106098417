import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {AddressService} from '../services/AddressService';
import {AddressStoreProps} from '../../types/app.types';

export type AddressStoreConfig = {
  flowAPI: ControllerFlowAPI;
  addressService: AddressService;
  updateComponent: () => void;
};

export class AddressStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly addressService: AddressService;
  private readonly updateComponent: () => void;

  constructor({flowAPI, addressService, updateComponent}: AddressStoreConfig) {
    this.flowAPI = flowAPI;
    this.addressService = addressService;
    this.updateComponent = updateComponent;
  }

  private readonly fetchAddresses = async (): Promise<void> => {
    await this.addressService.fetchAddressesInfo();
    this.updateComponent();
  };

  public toProps(): AddressStoreProps {
    return {
      fetchAddresses: this.fetchAddresses,
      addressesInfo: this.addressService.addressesInfo,
    };
  }
}
